import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	makeStyles,
} from '@material-ui/core';

import { BodyFeature } from './BodyFeature';

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		marginLeft: '1.2rem',
		fontSize: '1.3rem',
	},
	card: {
		// border: '1px solid #ECECEE',
		boxShadow: '0px .5px 2px rgba(0, 0, 0, 0.3)',
		borderRadius: '3px',
		cursor: 'pointer',
		background: theme.white,
		transition: 'transform 0.25s, box-shadow 0.75s',
	},
	expanded: {},
	root: {
		'&$expanded': {
			minHeight: 0,
		},
	},
	content: {
		margin: 0,
		'&$expanded': {
			margin: 0,
			minHeight: 'unset',
		},
	},
}));

export const MobileFeatureCard = ({
	featureSection,
	feature,
	featureRef,
	iosLogo,
	androidLogo,
	qbLogo,
	accentColor,
	index,
	expanded,
	handleChange,
}) => {
	const classes = useStyles();
	return (
		<Grid item xs={12}>
			<Accordion
				expanded={expanded === index ? true : false}
				className={classes.card}
				role="button"
				tabIndex={0}
				onChange={() => handleChange(index)}
				style={{
					borderRadius: '3px',
					border: '1px solid #E5EAF4',
					boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
				}}>
				<AccordionSummary
					style={{ padding: 0 }}
					classes={{
						content: classes.content,
						expanded: classes.expanded,
						root: classes.root,
					}}>
					<Grid
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'>
						<FontAwesomeIcon
							icon={['fad', feature.icon]}
							style={{
								height: '60px',
								width: '60px',
								border: `1px solid #E5EAF4`,
								background: '#E5EAF4',
								padding: '10px',
								color: feature.cardIconColor?.hexValue ?? '#002D5C',
							}}
						/>

						<Typography variant='h5' className={classes.title}>
							{feature.title}
						</Typography>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<BodyFeature
						feature={feature}
						accentColor={accentColor}
						featureRef={featureRef}
						iosLogo={iosLogo}
						androidLogo={androidLogo}
						qbLogo={qbLogo}
					/>
				</AccordionDetails>
			</Accordion>
		</Grid>
	);
};
