import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';

import { Grid, Typography, makeStyles, Button } from '@material-ui/core';

import { ListItem } from '../../CustomStyles/Typography';

const useStyles = makeStyles((theme) => ({
	featureCard: {
		padding: '2rem',
		background: '#FFFFFF',
		margin: '.5rem 0',
		cursor: 'pointer',
		border: '1px solid #E5EAF4',
		borderRadius: '10px',
		transition: 'all 0.35s ease !important',
	},
	// cardBody: {
	// 	lineHeight: '32px',
	// 	fontWeight: 400,
	// 	marginTop: '1rem',
	// },
	button: {
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
	},
}));

export const DesktopFeatures = ({ content, selected, setSelected }) => {
	const classes = useStyles();

	return (
		<Grid
			container
			alignItems='center'
			justifyContent='space-between'
			spacing={4}>
			<Grid container direction='column' item xs={5}>
				{content.map((card, index) => (
					<Grid
						item
						key={index}
						className={classes.featureCard}
						onClick={(e) => setSelected(card)}
						style={{
							boxShadow:
								selected._key === card._key
									? '0px 10px 40px rgba(0, 0, 0, 0.15)'
									: null,
							userSelect: 'none',
						}}>
						<Typography
							variant='h5'
							color={selected._key === card._key ? 'secondary' : 'primary'}>
							{card.header}
						</Typography>
						{/* <Typography variant='body1' className={classes.cardBody}>
							{card.subheader}
						</Typography> */}
					</Grid>
				))}
			</Grid>
			<Grid
				container
				justifyContent='flex-start'
				alignItems='center'
				item
				xs={7}
				spacing={2}>
				<Grid item>
					<GatsbyImage 
          style={{ maxWidth: 500, }}
          image={selected.image?.asset.gatsbyImageData} />
				</Grid>

				<Grid
					item
					container
					direction='column'
					alignItems='flex-start'
					justifyContent='space-evenly'>
					<PortableText
						content={selected._rawFeatureContent}
						// className={classes.text}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h2'
									style={{
										lineHeight: 0.9,
									}}>
									{children}
								</Typography>
							),
							normal: ({ children }) => (
								<Typography
									variant='body1'
									style={{
										fontSize: '1.5rem',
										color: '#4B5B69',
										fontFamily: 'Roboto',
									}}>
									{children}
								</Typography>
							),
							li: ({ children }) => (
								<Grid item style={{ marginBottom: '1rem' }}>
									<ListItem>
										<li style={{ fontSize: '1.5rem' }}>{children}</li>
									</ListItem>
								</Grid>
							),
						}}
					/>
				</Grid>
				<Grid container item>
					<Link to={`/${selected.ctaLink}`} style={{ textDecoration: 'none' }}>
						<Button
							variant='contained'
							size='large'
							color='primary'
							className={classes.button}
							style={{
								color: 'white',
								marginLeft: '1rem',
								marginTop: '1rem',
								padding: '16px 32px',
							}}>
							{selected.ctaText}
						</Button>
					</Link>
				</Grid>
			</Grid>
		</Grid>
	);
};
