import React, { useState, useEffect } from 'react';

import { Container, Grid, makeStyles } from '@material-ui/core';

import { Intro } from './Intro';
import { IntroCard } from './IntroCard';
import { CenterFeatures } from './CenterFeatures';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';

const useStyles = makeStyles((theme) => ({
	centerFeatures: {
		background: '#f5f9ff',
		padding: '14rem 0 16rem',
		[theme.breakpoints.down('sm')]: {
			padding: '10rem 0',
		},
	},
}));

export const WhyServiceBody = ({ whyService, id }) => {
	const [selected, setSelected] = useState(null);
	const { _rawIntro, introCards, centerSection } = whyService;

	useEffect(() => {
		setSelected(centerSection.content[0]);
	}, []);

	const classes = useStyles();

	return (
		<>
			<Container>
				<Intro intro={_rawIntro} />
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
					style={{ paddingTop: '4rem' }}
					spacing={4}>
					{introCards.map((card, index) => (
						<IntroCard card={card} key={index} />
					))}
				</Grid>
			</Container>
			<WaveDownSVG fill='#FFF' shadow />
			<div className={classes.centerFeatures}>
				<CenterFeatures
					centerSection={centerSection}
					setSelected={setSelected}
					selected={selected}
				/>
			</div>
			<WaveUpSVG fill='#FFF' shadow />
		</>
	);
};
